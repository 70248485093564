const isFormal = false
const OTTRADE_WEB_TEST_CDN = 'https://d2u6rc592zow96.cloudfront.net/ottrade-web-test-CDN/'
const OTTRADE_WEB_FORMAL_CDN = 'https://d2u6rc592zow96.cloudfront.net/ottrade-web-CDN/'

const FORMAL_URL = 'https://www.otrading.io'
const TEST_URL = 'https://test.ottradex.com'

module.exports = {
  OTTRADE_WEB_TEST_CDN,
  OTTRADE_WEB_FORMAL_CDN,
  isFormal,
  FORMAL_URL,
  TEST_URL,
}