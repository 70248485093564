/**
 * 注册页面
 * 2022年5月7日
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHttpPost } from "react-http-fetch";
import { useWindowSize } from "../hooks/useWindowSize";
import closeImg from "../assets/img/team_icon_close_fill.png"
import { verifyCode, verifyEmail, verifyPwd, isEmpty } from "../utils/verify";
import md5 from 'js-md5';

export default function Register(props) {
  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const [isHintEmail, setHintIsEmail] = useState(false);
  const [eMail, setEmail] = useState("");

  const [verification, setVerification] = useState("");

  const [isHintPws, setIsHintPws] = useState(false);
  const [pws, setPws] = useState("");

  const [isHintSurePws, setIsHintSurePws] = useState(false);
  const [surepws, setSurePws] = useState("");

  const [invitationCode, setInvitationCode] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [authTime, setAuthTime] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [singUpLoadings, setSingUpLoadings] = useState(false);
  const { code } = props
  useEffect(() => {
    if (code) {
      setInvitationCode(code);
    }
  }, []);

  //=============================================================================//

  //获取验证码
  const [, createVerificationRequest] = useHttpPost({ relativeUrl: "mailCode" });
  const getVerification = async () => {
    //禁用点击
    if (isAuth) {
      return;
    }

    //邮件
    if (isEmpty(eMail)) {
      messageApi.open({
        type: 'error',
        content: t("pleaseMail")
      });
      return;
    }

    //请输入正确的邮箱
    if (!verifyEmail(eMail)) {
      messageApi.open({
        type: 'error',
        content: t("toatMail")
      });
      return;
    }

    try {
      let payload = { mail: eMail };
      setIsAuth(true);
      const { reqResult } = await createVerificationRequest({ requestOptions: { body: payload } });
      reqResult.then((res) => {
        if (res.code === 0) {
          messageApi.open({
            type: 'success',
            content: t("authSendSucc")
          });
          startAuthTimer();
        } else {
          messageApi.open({
            type: 'error',
            content: t(`${res.code}`)
          });
          setIsAuth(false);
        }
      });
    } catch (error) {
      console.error(error);
      setIsAuth(false);
      messageApi.open({
        type: 'error',
        content: t(`${20010}`)
      });
    }
  };

  //验证码倒计时
  const calcAuthTime = () => {
    setAuthTime((n) => {
      let t = n - 1;
      if (t <= 0) {
        t = 0;
        setIsAuth(false);
        clearInterval(authTimer);
      }
      return t;
    });
  };

  //启动倒计时
  let authTimer = null;
  const startAuthTimer = () => {
    setAuthTime(240);
    authTimer = setInterval(calcAuthTime, 1000);
  };

  //开始注册
  const [, createRegisterRequest] = useHttpPost({ relativeUrl: "regist" });
  const createRegister = async (event) => {
    event.preventDefault();

    //请输入正确的邮箱
    if (!verifyEmail(eMail)) {
      messageApi.open({
        type: 'error',
        content: t("toatMail")
      });
      return;
    }

    //验证码
    if (isEmpty(verification)) {
      messageApi.open({
        type: 'error',
        content: t('pleaseCode')
      });
      //Toast.show({ content: t("pleaseCode") });
      return;
    }

    //邀请码
    // if (isEmpty(invitationCode)) {
    //   messageApi.open({
    //     type: 'error',
    //     content: t('pleaseInCode')
    //   });
    //   return;
    // }


    //输入密码
    if (isEmpty(pws)) {
      messageApi.open({
        type: 'error',
        content: t('pleasePass')
      });
      //Toast.show({ content: t("pleasePass") });
      return;
    }



    if (!verifyPwd(pws)) {
      messageApi.open({
        type: 'error',
        content: t('toatError')
      });
      // Toast.show({ content: t("toatError") });
      return;
    }

    if (pws != surepws) {
      messageApi.open({
        type: 'error',
        content: t('toatPassword')
      });
      //Toast.show({ content: t("toatPassword") });
      return;
    }

    try {
      let payload = {
        mail: eMail,
        verification: verification,
        password: md5(md5(pws)),
        invitation: invitationCode,
        regist_type: 2,
        channel: 'pc'
      };
      setIsDisabled(true);
      const { reqResult } = await createRegisterRequest({ requestOptions: { body: payload } });
      reqResult.then((res) => {
        if (res.code === 0) {
          messageApi.open({
            type: "success",
            content: t('regSendSucc'),
            duration: 5000
          });

          handleOnClick(1)
          setIsSuccess(true);
          setIsDisabled(false);
        } else {
          messageApi.open({
            type: "error",
            content: t(`${res.code}`)
          });
          // Toast.show({ content: t(`${res.code}`) });
          resetAuthTimer();
          setIsDisabled(false);
        }
      });
    } catch (error) {
      console.error(error);
      setIsDisabled(false);
      // Toast.show({ content: t(`${99999}`) });
    }
  };

  //重置验证码时间
  const resetAuthTimer = () => {
    if (authTime > 0) {
      setAuthTime(0);
      setIsAuth(false);
      clearInterval(authTimer);
    }
  };

  const handleOnClick = (isShowSuccess) => {
    const { handleClose } = props
    handleClose(false, isShowSuccess)

  }
  return (
    <>
      {contextHolder}
      <div style={{ display: "flex", alignContent: 'center', justifyContent: 'center', zIndex: 800 }}>
        <div style={{ width: isMobile ? "95%" : '100vw', marginTop: "5px" , marginBottom:"20px", height: "100vh", overflow: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* <div style={{ height: isMobile ? -50 : 120 }}></div> */}
          <div style={{ width: '100%', display: 'flex', alignContent: "center", justifyContent: "center", height: 640, marginTop: isMobile ? 70 : 0, marginBottom: 50 }}>
            <div style={{ display: "flex", alignContent: 'center', justifyContent: 'center', flexDirection: 'column', }} className="fGgPzw">
              <div style={{ position: 'absolute', right: 6, top: 4 }}>
                <img src={closeImg} style={{ width: 40, height: 40, cursor: "pointer", zIndex: 200 }} onClick={() => handleOnClick(0)} />
              </div>
              <div style={{ color: '#fff', fontWeight: "bold", fontSize: 20 }}>{t("title")}</div>
              <InputDiv>
                <div style={{ color: "#fff" }}>{t("email")}</div>
                <Input
                  type={"text"}
                  enterKeyHint={"next"}
                  maxLength={36}
                  placeholder={t("pleaseMail")}
                  style={{ marginTop: "20px", background: " rgba(255, 255, 255, 0.2)", height: 40, border: "none", color: "#fff", fontSize: 16 }}
                  onBlur={() => {
                    setHintIsEmail(isEmpty(eMail));
                  }}
                  value={eMail}
                  onChange={(e) => {

                    setHintIsEmail(!verifyEmail(eMail));
                    setEmail(e.target.value);
                    resetAuthTimer();
                  }}
                />
              </InputDiv>
              {/* {isHintEmail || !isMobile && <TipSpan>{t("toatMail")}</TipSpan>} */}
              <InputDiv>
                <div style={{ color: "#fff" }}>{t("code")}</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                  <Input
                    type={"text"}
                    enterKeyHint={"next"}
                    maxLength={6}
                    placeholder={t("pleaseCode")}
                    style={{ width: isMobile ? "50%" : "70%", background: " rgba(255, 255, 255, 0.2)", height: 40, border: "none", color: "#fff", fontSize: 16 }}
                    value={verification}
                    onChange={(e) => {
                      let value = e.target.value;
                      setVerification(value);
                    }}
                  />
                  <div style={{ height: 40, }}>
                    {
                      authTime > 0 ? (
                        <Button style={{ fontSize: "12px", backgroundColor: "#6C6AEB", color: "#FFFFFF", border: 'none' }}>
                          {authTime}
                        </Button>
                      ) : (
                        <Button loading={isAuth} style={{ fontSize: "12px", backgroundColor: "#6C6AEB", color: "#FFFFFF", border: 'none' }} onClick={getVerification}>
                          {t("sendCode")}
                        </Button>
                      )
                    }

                  </div>
                </div>
              </InputDiv>

              <InputDiv>
                <div style={{ color: "#fff" }}>{t("loginPassword")}</div>
                <Input
                  type={"password"}
                  enterKeyHint={"next"}
                  maxLength={100}
                  placeholder={t("pleasePass")}
                  style={{ marginTop: "20px", background: " rgba(255, 255, 255, 0.2)", height: 40, border: "none", color: "#fff", fontSize: 16 }}
                  clearable
                  value={pws}
                  onChange={(e) => {
                    setIsHintPws(!verifyPwd(e.target.value));
                    setPws(e.target.value);
                  }}
                />
              </InputDiv>
              {isHintPws && <div style={{ color: 'red', fontSize: 12, fontWeight: "bold", marginTop: 4 }}>{t("toatError")}</div>}

              <InputDiv>
                <div style={{ color: "#fff" }}>{t("confirmPassword")}</div>
                <Input
                  type={"password"}
                  enterKeyHint={"done"}
                  maxLength={100}
                  placeholder={t("confirmPlaceholder")}
                  style={{ marginTop: "20px", background: " rgba(255, 255, 255, 0.2)", height: 40, border: "none", color: "#fff", fontSize: 16 }}
                  onBlur={() => {
                    setIsHintSurePws(isEmpty(surepws));
                  }}
                  clearable
                  value={surepws}
                  onChange={(e) => {
                    setIsHintSurePws(!verifyPwd(surepws));
                    setSurePws(e.target.value);
                  }}
                />
                {/* {isHintSurePws && <TipSpan>{t("confirmPassword")}</TipSpan>} */}
              </InputDiv>

              <InputDiv>
                <div style={{ color: "#fff" }}>{t("invitationCode")}</div>
                <Input
                  type={"text"}
                  enterKeyHint={"done"}
                  disabled={code}
                  maxLength={6}
                  placeholder={t("pleaseInCode")}
                  style={{ marginTop: "20px", background: " rgba(255, 255, 255, 0.2)", height: 40, border: "none", color: "#fff", fontSize: 16 }}
                  clearable
                  value={invitationCode}
                  onChange={(e) => {
                    setInvitationCode(e.target.value);
                  }}
                />
              </InputDiv>

              <div style={{ marginTop: 20 }}>
                <Button loading={isDisabled} block style={{ marginTop: "15px", backgroundColor: "#6C6AEB", color: "#FFFFFF", border: "none", height: 40 }} onClick={createRegister}>
                  {isSuccess ? t("regSendSucc") : t("title")}
                </Button>
                {/* <Button block style={{ marginTop: "15px", backgroundColor: isSuccess ? "#6C6AEB" : "#FFFFFF", color: isSuccess ? "#FFFFFF" : "#CCCCCC", border: "none", height: 40 }} onClick={gotoDownload}>
                  {t("download")}
                </Button> */}
              </div>
            </div>
          </div>
          {/* {
            !isMobile && (<div style={{ position: 'absolute', right: 0, left: 0, bottom: -30 }}>
              <Food />
            </div>)
          } */}
        </div>
      </div>
    </>
  );
}

/**
 * 输入DIV
 */
const InputDiv = styled.div`
  position: relative;
  margin-top: 20px;
  
`;

/**
 * 提示span
 */
const TipSpan = styled.span`
  position: absolute;
  top: 50px;
  right: 10px;
  color: #fff;
  font-size: 15px;
`;
