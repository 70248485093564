/**
 * 常用工具
 * 2022年5月7日
 */
import queryString from "query-string";

export const ROUTERURl = ['/', '/Home', '/ottrade']

export const useRotuRreferralId = () => {
  let isRouterUrl = true
  let str = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ"
  const pathname = window.location.pathname
  if (pathname.length != 7) {
    return isRouterUrl = true
  }
  for (var i = 0; i < ROUTERURl.length; i++) {
    if (ROUTERURl.indexOf(pathname) == -1) {
      for (var j = 1; j < pathname.length; j++) {
        const ch = pathname.charAt(j);
        if (str.indexOf(ch) == -1) {
          isRouterUrl = true
          break
        } else {
          isRouterUrl = false
        }
      }
    } else {
      isRouterUrl = true
      break
    }
  }
  return isRouterUrl
}

export const imgUrl = "https://d2u6rc592zow96.cloudfront.net/img/"
//获取URL参数
export const getUrlCode = () => {
  const urlSearch = decodeURIComponent(window.location.search);
  const parsed = queryString.parse(urlSearch);
  if (parsed) {
    if (parsed.code && (parsed.code + "").length === 6) {
      return parsed.code;
    }
  }
  let url = decodeURIComponent(window.location);
  let params = url.split("/");
  const len = params.length;
  //如果最后一位长度是6
  if (params[len - 1].length === 6) {
    return unescape(params[len - 1]);
  }
  //如果最后一位长度是0，取倒数第二位的
  if (params[len - 1].length === 0) {
    if (params[len - 2].length === 6) {
      return unescape(params[len - 2]);
    }
  }
  return "";
};

//语言简码
export const language = () => {
  let l = localStorage.getItem("lan");
  if (l) {
    return l;
  }
  let lang = navigator.language;
  if (lang.indexOf("TW") > -1) {
    return "hk";
  }
  if (lang.indexOf("HK") > -1) {
    return "hk";
  }
  if (lang.indexOf("zh") > -1) {
    return "cn";
  }
  if (lang.indexOf("ru") > -1) {
    return "ru";
  }
  if (lang.indexOf("pt") > -1) {
    return "pt";
  }
  if (lang.indexOf("vi") > -1) {
    return "vi";
  }
  if (lang.indexOf("ja") > -1) {
    return "jp";
  }
  // if (lang.indexOf("th") > -1) {
  //   return "Th";
  // }
  if (lang.indexOf("ko") > -1) {
    return "ko";
  }
  // if (lang.indexOf("ms") > -1) {
  //   return "Ms";
  // }
  if (lang.indexOf("id") > -1) {
    return "ba";
  }
  // if (lang.indexOf("hi") > -1) {
  //   return "Hi";
  // }
  if (lang.indexOf("fr") > -1) {
    return "fr";
  }
  if (lang.indexOf("es") > -1) {
    return "es";
  }
  return "en";
};
